const sidePanelVuexModule = {
  namespaced: true,
  state: { isVisible: false, currentRouteName: null },
  getters: {
    isVisible: (state) => state.isVisible,
    currentRouteName: (state) => state.currentRouteName,
  },
  mutations: {
    setIsVisible(state, isVisible) {
      state.isVisible = isVisible;
    },
    setCurrentRouteName(state, routeName) {
      state.currentRouteName = routeName;
    },
  },
  actions: {
    show({ commit }) {
      commit("setIsVisible", true);
    },
    changeRouteName({ commit }, routeName) {
      commit("setCurrentRouteName", routeName);
    },
    hide({ commit }) {
      commit("setIsVisible", false);
      commit("setCurrentRouteName", null);
    },
  },
};

export default sidePanelVuexModule;
