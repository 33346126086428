import api from "./api.service";

export default class AuthService {
  static PATH = "/auth";

  static googleSignIn(idToken) {
    return api.post(
      `${AuthService.PATH}/google`,
      {},
      {
        params: {
          idToken,
        },
      }
    );
  }

  static sendEmailLink(email) {
    return api.post(
      `${AuthService.PATH}/email/send`,
      {},
      {
        params: {
          email,
        },
      }
    );
  }

  static emailLinkSignIn(data) {
    return api.post(`${AuthService.PATH}/email/signIn`, data);
  }

  static refresh(refreshToken) {
    return api.get(`${AuthService.PATH}/refresh`, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
  }
}
