// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4qwlo_SCoT2CUOn38xij5Va0_CTDQe6Y",
  authDomain: "bunkai-auth.firebaseapp.com",
  projectId: "bunkai-auth",
  storageBucket: "bunkai-auth.appspot.com",
  messagingSenderId: "14960893937",
  appId: "1:14960893937:web:83642db96fb1d9ee87ee50",
  measurementId: "G-4C52YLTTL7",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
