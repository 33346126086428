import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/views/MainLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "angles",
        name: "angles-panel",
        components: {
          SidePanel: () => import("@/components/Angles.vue"),
        },
        meta: {
          titleKey: "angles.title",
        },
      },
      {
        path: "videos",
        name: "videos-panel",
        components: {
          SidePanel: () => import("@/components/Videos.vue"),
        },
        meta: {
          titleKey: "videos.title",
        },
      },
      {
        path: "bladeflex",
        name: "bladeflex-panel",
        components: {
          SidePanel: () => import("@/components/BladeflexPanel.vue"),
        },
        meta: {
          titleKey: "bladeflex.title",
        },
      },
      {
        path: "support",
        name: "support-panel",
        components: {
          SidePanel: () => import("@/components/Support.vue"),
        },
        meta: {
          titleKey: "support.title",
        },
      },
      {
        path: "settings",
        name: "settings-panel",
        components: {
          SidePanel: () => import("@/components/Settings.vue"),
        },
        meta: {
          titleKey: "settings.title",
        },
      },
      {
        path: "admin",
        name: "admin-panel",
        components: {
          SidePanel: () => import("@/components/AdminPanel.vue"),
        },
        meta: { requiresAdmin: true, titleKey: "admin.title" },
      },
    ],
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/SignIn.vue"),
    meta: {
      requiresAnonymous: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  if (
    to.meta?.requiresAdmin &&
    store.getters["auth/isSignedIn"] &&
    store.getters["auth/user"].role === "admin"
  ) {
    next();
    return;
  }

  if (to.meta?.requiresAnonymous && store.getters["auth/isSignedIn"]) {
    next({ name: "main" });
    return;
  } else if (to.meta?.requiresAuth && !store.getters["auth/isSignedIn"]) {
    next({ name: "signin" });
    return;
  }
  next();
});

export default router;
