import { isNullOrUndefined } from "@/utils/misc";

const authVuexModule = {
  namespaced: true,
  state: {
    user: null,
    status: { isSignedIn: false },
    accessToken: null,
    refreshToken: null,
    group: null,
  },
  getters: {
    user: (state) => state.user,
    isSignedIn: (state) => state.status.isSignedIn,
    accessToken: (state) => state.accessToken,
    refreshToken: (state) => state.refreshToken,
    isGroupManager: (state) => {
      return (
        !isNullOrUndefined(state.group) &&
        !isNullOrUndefined(state.user) &&
        state.group.managerId === state.user.id
      );
    },
    hasGroup: (state) => !isNullOrUndefined(state.group),
    group: (state) => state.group,
  },
  mutations: {
    signinSuccess(state, { user, accessToken, refreshToken }) {
      state.user = user;
      state.status.isSignedIn = true;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.group = user.group || null;
    },
    reinitializeState(state) {
      state.user = null;
      state.status.isSignedIn = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.group = null;
    },
    refreshTokens(state, { accessToken, refreshToken }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setShowTutorial(state, showTutorial) {
      if (!isNullOrUndefined(state.user)) {
        state.user.showTutorial = showTutorial;
      }
    },
    setGroup(state, group) {
      state.group = group;
    },
  },
  actions: {
    signin({ commit }, { user, accessToken, refreshToken }) {
      commit("signinSuccess", {
        user,
        accessToken,
        refreshToken,
      });
    },
    signout({ commit }) {
      commit("reinitializeState");
    },
    refreshTokens({ commit }, { accessToken, refreshToken }) {
      commit("refreshTokens", {
        accessToken,
        refreshToken,
      });
    },
    disableTutorial({ commit }) {
      commit("setShowTutorial", false);
    },
    updateGroup({ commit }, group) {
      commit("setGroup", group);
    },
  },
};

export default authVuexModule;
