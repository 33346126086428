import enMessages from "./en.json";
import frMessages from "./fr.json";
import nlMessages from "./nl.json";
import jpnMessages from "./jpn.json";

const translations = {
  en: {
    messages: enMessages,
  },
  fr: {
    messages: frMessages,
  },
  nl: {
    messages: nlMessages,
  },
  jpn: {
    messages: jpnMessages,
  },
};

export default translations;
