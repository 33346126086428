import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";

import * as poseDetection from "@tensorflow-models/pose-detection";

import { isNullOrUndefined } from "./misc";

const DETECTOR_CONFIG = {
  maxPoses: 1,
  enableTracking: false,
};

export async function initAndGetDetector(modelType) {
  return await poseDetection.createDetector(
    poseDetection.SupportedModels.MoveNet,
    { ...DETECTOR_CONFIG, modelType }
  );
}

export async function getKeypoints(detector, feed) {
  if (!isNullOrUndefined(detector) && !isNullOrUndefined(feed)) {
    const poses = await detector.estimatePoses(feed);
    if (!isNullOrUndefined(poses) && poses.length > 0) {
      return poses;
    }
  }

  return [];
}
