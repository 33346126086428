import api from "@/services/api.service";
import AuthService from "@/services/auth.service";
import router from "@/router";
import store from "@/store";
import { isNullOrUndefined } from "../misc";

export const setupInterceptors = () => {
  api.interceptors.request.use(
    (config) => {
      config.headers["Accept-Language"] = store.getters["settings/locale"];
      const accessToken = store.getters["auth/accessToken"];

      if (
        isNullOrUndefined(config.headers["Authorization"]) &&
        !isNullOrUndefined(accessToken)
      ) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      const originalConfig = err.config;
      if (!originalConfig.url.includes("/auth")) {
        if (
          (err.response.status === 401 || err.response.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          try {
            const response = await AuthService.refresh(
              store.getters["auth/refreshToken"]
            );

            store.dispatch("auth/signin", response.data);

            return api(originalConfig);
          } catch (error) {
            store.dispatch("auth/signout");
            router.push({ name: "signin" });
            return Promise.reject(error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};
