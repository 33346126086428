export default {
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux en cours..."])},
  "loading_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IA est en cours de chargement..."])},
  "feed": {
    "background_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la webcam ne fonctionne pas, appuyez sur le bouton de menu."])},
    "back_to_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la caméra"])},
    "screenshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre une capture d'écran"])},
    "full_screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plein écran"])}
  },
  "angles": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angles"])},
    "tabs": {
      "bones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["squelette"])},
      "joints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["articulations"])},
      "figure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["silhouette"])}
    },
    "bones": {
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tête"])},
      "neck_lateral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cou (latéral)"])},
      "shoulders_tilt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épaules (inclinaison)"])},
      "left_upper_arm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut du bras gauche"])},
      "left_forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant-bras gauche"])},
      "left_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côté gauche"])},
      "right_upper_arm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut du bras droit"])},
      "right_forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant-bras droit"])},
      "right_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côté droit"])},
      "hips_tilt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanches (inclinaison)"])},
      "left_thigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuisse gauche"])},
      "left_calf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollet gauche"])},
      "right_thigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuisse droite"])},
      "right_calf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollet droit"])}
    },
    "joints": {
      "left_shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épaule gauche"])},
      "right_shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épaule droite"])},
      "left_elbow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coude gauche"])},
      "right_elbow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coude droite"])},
      "left_hip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanche gauche"])},
      "right_hip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanche droite"])},
      "left_knee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genou gauche"])},
      "right_knee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genou droite"])}
    },
    "buttons": {
      "swap_arches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retourner l'arc"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cible"])},
      "draw_joint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dessiner l'articulation"])}
    }
  },
  "videos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockage"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéos / Images"])},
    "tabs": {
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vidéos"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images"])}
    },
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger une video"])},
    "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger une image"])},
    "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paramètres"])},
    "tabs": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["générale"])},
      "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ia"])}
    }
  }
}