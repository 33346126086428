<template>
  <div class="lds-dual-ring"></div>
</template>

<script>
export default {
  name: "spinner-ui",
};
</script>

<style lang="scss" scoped>
.lds-dual-ring {
  display: inline-block;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
