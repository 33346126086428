export default {
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signin"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signout"])},
  "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work in progress..."])},
  "loading_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading AI..."])},
  "feed": {
    "background_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If camera is not working, press menu button."])},
    "start_recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start recording"])},
    "stop_recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop recording"])},
    "open_drawing_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawing menu"])},
    "back_to_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to camera"])},
    "screenshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture video or image"])},
    "full_screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full screen"])},
    "drawing": {
      "erase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eraser"])},
      "erase_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erase all"])}
    }
  },
  "player": {
    
  },
  "angles": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angles"])},
    "tabs": {
      "bones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skeleton"])},
      "joints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joints"])},
      "figure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["figure"])}
    },
    "bones": {
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head"])},
      "neck_lateral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neck (lateral)"])},
      "shoulders_tilt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoulders (tilt)"])},
      "left_upper_arm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left upper arm"])},
      "left_forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left forearm"])},
      "left_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left side"])},
      "right_upper_arm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right upper arm"])},
      "right_forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right forearm"])},
      "right_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right side"])},
      "hips_tilt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hips (tilt)"])},
      "left_thigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left thigh"])},
      "left_calf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left calf"])},
      "right_thigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right thigh"])},
      "right_calf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right calf"])}
    },
    "joints": {
      "left_shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left shoulder"])},
      "right_shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right shoulder"])},
      "left_elbow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left elbow"])},
      "right_elbow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right elbow"])},
      "left_hip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left hip"])},
      "right_hip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right hip"])},
      "left_knee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left knee"])},
      "right_knee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right knee"])},
      "trunk_latero_flexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trunk latero flexion"])},
      "left_ankle_dorsiflexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left ankle dorsiflexion"])},
      "right_ankle_dorsiflexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right ankle dorsiflexion"])}
    },
    "trackables": {
      "left_wrist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left wrist"])},
      "right_wrist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right wrist"])}
    },
    "tooltips": {
      "trunk_latero_flexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1: uses the perpendicular line to the hips_tilt\n\n2: uses the center body line"])},
      "trackable_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot be activated as an angle,\n you can only track its movement"])}
    },
    "buttons": {
      "swap_arches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["turn around arches"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["target"])},
      "draw_joint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draw joint"])},
      "track_joint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["track joint"])}
    }
  },
  "videos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storage"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos / Images"])},
    "tabs": {
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["videos"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images"])}
    },
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload video"])},
    "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload image"])},
    "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
  },
  "bladeflex": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bladeflex"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessments / Exercises"])},
    "tabs": {
      "assessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assessments"])},
      "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercises"])}
    },
    "inspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspect"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "add_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add assessment"])},
    "add_exercise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add exercise"])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "tabs": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])},
      "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ai"])}
    },
    "tooltips": {
      "ai_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can be put higher for increased accuracy (at the cost of detection) or lower to generate more lines / angles at the cost of accuracy. Default = 0.3"])},
      "delta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The accuracy of your targets if you set any. For example: if you set a target of 90° and a delta of 10° then the lines will go green between 80°-100° (minus 10 and plus 10°)"])},
      "track_fade_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The time delay before your joint tracing disappears from screen."])}
    }
  },
  "admin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])}
  }
}