import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

import auth from "./auth.module";
import settings from "./settings.module";
import sidePanel from "./sidePanel.module";
import detector from "./detector.module";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    auth: {
      user: state.auth.user,
      status: state.auth.status,
      refreshToken: state.auth.refreshToken,
      group: state.auth.group,
    },
    detector: {
      modelType: state.detector.modelType,
    },
    settings: {
      ...state.settings,
    },
  }),
  filter: (mutation) => {
    const mutations = [
      "auth/signinSuccess",
      "auth/reinitializeState",
      "auth/setGroup",
      "auth/refreshTokens",
      "detector/setModelType",
    ];
    const modules = ["settings/"];
    return (
      mutations.includes(mutation.type) ||
      modules.some((m) => mutation.type.includes(m))
    );
  },
});

export default createStore({
  state: {
    videoId: null,
    showTutorial: false,
    trackedJoints: [],
    assessmentId: null,
    referencePoint: null,
    exerciseId: null,
  },
  getters: {
    videoId: (state) => state.videoId,
    showTutorial: (state) => state.showTutorial,
    trackedJoints: (state) => state.trackedJoints,
    assessmentId: (state) => state.assessmentId,
    referencePoint: (state) => state.referencePoint,
    exerciseId: (state) => state.exerciseId,
  },
  mutations: {
    setVideoId(state, videoId) {
      state.videoId = videoId;
    },
    setShowTutorial(state, showTutorial) {
      state.showTutorial = showTutorial;
    },
    trackJoint(state, jointName) {
      state.trackedJoints.push(jointName);
    },
    untrackJoint(state, jointName) {
      state.trackedJoints = state.trackedJoints.filter((a) => a !== jointName);
    },
    setAssessment(state, { id, referencePoint }) {
      state.assessmentId = id;
      state.referencePoint = referencePoint;
    },
    setExercise(state, { id }) {
      state.exerciseId = id;
    },
  },
  actions: {
    updateVideoId({ commit }, videoId) {
      commit("setVideoId", videoId);
    },
    updateShowTutorial({ commit }, showTutorial) {
      commit("setShowTutorial", showTutorial);
    },
    trackJoint({ commit }, jointName) {
      commit("trackJoint", jointName);
    },
    untrackJoint({ commit }, jointName) {
      commit("untrackJoint", jointName);
    },
    updateAssessment({ commit }, { id, referencePoint }) {
      commit("setAssessment", { id, referencePoint });
    },
    updateExercise({ commit }, { id }) {
      commit("setExercise", { id });
    },
  },
  modules: { auth, settings, sidePanel, detector },
  plugins: [vuexLocal.plugin],
});
