import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { app } from "./utils/firebase/initFirebase";
import i18n from "./i18n";
import { setupInterceptors } from "./utils/axios/interceptors";

import directives from "./directives/";

import Popper from "vue3-popper";
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import Tooltip from "@/components/ui/Tooltip.vue";
import Spinner from "@/components/ui/Spinner.vue";

setupInterceptors();

const vueApp = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(SnackbarService)
  .provide("firebase-app", app);

directives(vueApp)
  .component("popper", Popper)
  .component("toast", Vue3Snackbar)
  .component("tooltip", Tooltip)
  .component("spinner", Spinner);

vueApp.mount("#app");
