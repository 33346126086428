<template>
  <div class="w-100 h-100 overflow-hidden" :style="colors">
    <router-view />
    <toast top right :duration="1000"> </toast>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    // color-primary-500, color-secondary-500, color-accent-500 are the primary, secondary, and accent colors
    // a difference of 100 is a 20% difference in shading https://maketintsandshades.com/
    const themes = {
      dark: {
        "--color-primary-600": "#07151d",
        "--color-primary-500": "#091a24",
        "--color-secondary-600": "#163549",
        "--color-secondary-500": "#1c425b",
        "--color-secondary-400": "#49687c",
        "--bg-secondary": "#1c425b",
        "--color-accent-600": "#98001e",
        "--color-accent-500": "#be0026",
        "--color-accent-400": "#cb3351",
        "--placeholder-text-color": "#acacac",
      },
      light: {
        "--color-primary-600": "#3a5363",
        "--color-primary-500": "#49687c",
        "--color-secondary-600": "#72818a",
        "--color-secondary-500": "#8ea1ad",
        "--color-secondary-400": "#a5b4bd",
        "--bg-secondary": "#8ea1ad",
        "--color-accent-600": "#98001e",
        "--color-accent-500": "#be0026",
        "--color-accent-400": "#cb3351",
        "--placeholder-text-color": "#acacac",
      },
      bladeFlex: {
        "--color-primary-600": "#0a0b0e",
        "--color-primary-500": "#0d0e12",
        "--color-secondary-600": "#111416",
        "--color-secondary-500": "#15191c",
        "--color-secondary-400": "#444749",
        "--bg-secondary": "#15191c",
        "--color-accent-600": "#b41633",
        "--color-accent-500": "#e11c40",
        "--color-accent-400": "#e74966",
        "--placeholder-text-color": "#acacac",
      },
      aventuzAcademy: {
        "--color-primary-600": "#081c2d",
        "--color-primary-500": "#0a2338",
        "--color-secondary-600": "#5f5e5e",
        "--color-secondary-500": "#777575",
        "--color-secondary-400": "#929191",
        "--bg-secondary": "#777575",
        "--color-accent-600": "#27b60e",
        "--color-accent-500": "#31e411",
        "--color-accent-400": "#5ae941",
        "--placeholder-text-color": "#fff",
      },
    };

    const theme = computed(() => store.getters["settings/theme"]);

    const colors = computed(() => themes[theme.value]);

    onMounted(() => {
      store.dispatch("settings/updateLocale", store.getters["settings/locale"]);
    });

    return {
      colors,
    };
  },
};
</script>

<style lang="scss">
@import "styles/main";

::placeholder {
  color: var(--placeholder-text-color);
  opacity: 1;
}
</style>
