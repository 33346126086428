export default {
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inloggen"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uitloggen"])},
  "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onder constructie..."])},
  "loading_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI wordt geladen..."])},
  "feed": {
    "background_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als de camera niet werkt, drukt u op de menuknop."])},
    "back_to_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar camera"])},
    "screenshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een screenshot"])},
    "full_screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volledig scherm"])}
  },
  "angles": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoeken"])},
    "tabs": {
      "bones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skelet"])},
      "joints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gewrichten"])},
      "figure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["figuur"])}
    },
    "bones": {
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofd"])},
      "neck_lateral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neck (lateraal)"])},
      "shoulders_tilt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schouders (kanteling)"])},
      "left_upper_arm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker bovenarm"])},
      "left_forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker onderarm"])},
      "left_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkerkant"])},
      "right_upper_arm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter bovenarm"])},
      "right_forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter onderarm"])},
      "right_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechterkant"])},
      "hips_tilt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heupen (kanteling)"])},
      "left_thigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker dij"])},
      "left_calf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker kuit"])},
      "right_thigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter dij"])},
      "right_calf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter kuit"])}
    },
    "joints": {
      "left_shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker schouder"])},
      "right_shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter schouder"])},
      "left_elbow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker elleboog"])},
      "right_elbow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter elleboog"])},
      "left_hip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker heup"])},
      "right_hip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter heup"])},
      "left_knee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker knie"])},
      "right_knee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter knie"])}
    },
    "buttons": {
      "swap_arches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draai bogen om"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doelwit"])},
      "draw_joint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teken gewricht"])}
    }
  },
  "videos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opslag"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos / Afbeeldingen"])},
    "tabs": {
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["videos"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afbeeldingen"])}
    },
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video's opladen"])},
    "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding uploaden"])},
    "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analyseren"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaden"])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instellingen"])},
    "tabs": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profiel"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algemeen"])},
      "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ai"])}
    }
  }
}