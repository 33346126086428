import i18n from "@/i18n";

const settingsVuexModule = {
  namespaced: true,
  state: {
    locale: "en",
    theme: "dark",
    camera: {
      label: "",
      value: "",
    },
    lineColor: "#ff0033",
    trackingColor: "#d6d6d6",
    canvasTextSize: 20,
    arcSize: 50,
    delta: 10,
    trackFadeOut: 1,
    drawingStyles: {
      font: 20,
      lineWidth: 4,
      eraserSize: 20,
    },
    countdown: 5,
  },
  getters: {
    locale: (state) => state.locale,
    theme: (state) => state.theme,
    camera: (state) => state.camera,
    lineColor: (state) => state.lineColor,
    trackingColor: (state) => state.trackingColor,
    canvasTextSize: (state) => state.canvasTextSize,
    arcSize: (state) => state.arcSize,
    delta: (state) => state.delta,
    trackFadeOut: (state) => state.trackFadeOut,
    drawingStyles: (state) => state.drawingStyles,
    countdown: (state) => state.countdown,
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
      i18n.global.locale.value = locale;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setCamera(state, camera) {
      state.camera = camera;
    },
    setLineColor(state, lineColor) {
      state.lineColor = lineColor;
    },
    setTrackingColor(state, trackingColor) {
      state.trackingColor = trackingColor;
    },
    setCanvasTextSize(state, canvasTextSize) {
      state.canvasTextSize = canvasTextSize;
    },
    setArcSize(state, arcSize) {
      state.arcSize = arcSize;
    },
    setDelta(state, delta) {
      state.delta = delta;
    },
    setTrackFadeOut(state, trackFadeOut) {
      state.trackFadeOut = trackFadeOut;
    },
    setDrawingStyles(state, drawingStyles) {
      state.drawingStyles = drawingStyles;
    },
    setCountdown(state, countdown) {
      state.countdown = countdown;
    },
  },
  actions: {
    updateLocale({ commit }, locale) {
      commit("setLocale", locale);
    },
    updateTheme({ commit }, theme) {
      commit("setTheme", theme);
    },
    updateCamera({ commit }, camera) {
      commit("setCamera", camera);
    },
    updateLineColor({ commit }, lineColor) {
      commit("setLineColor", lineColor);
    },
    updateTrackingColor({ commit }, trackingColor) {
      commit("setTrackingColor", trackingColor);
    },
    updateCanvasTextSize({ commit }, textSize) {
      commit("setCanvasTextSize", textSize);
    },
    updateArcSize({ commit }, arcSize) {
      commit("setArcSize", arcSize);
    },
    updateDelta({ commit }, delta) {
      commit("setDelta", delta);
    },
    updateTrackFadeOut({ commit }, trackFadeOut) {
      commit("setTrackFadeOut", trackFadeOut);
    },
    updateDrawingStyles({ commit }, drawingStyles) {
      commit("setDrawingStyles", drawingStyles);
    },
    updateCountdown({ commit }, countdown) {
      commit("setCountdown", countdown);
    },
  },
};

export default settingsVuexModule;
