export const AbsoluteAnglesNames = Object.freeze({
  HEAD: "head",
  NECK_LATERAL: "neck_lateral",
  SHOULDERS_TILT: "shoulders_tilt",
  LEFT_UPPER_ARM: "left_upper_arm",
  LEFT_LOWER_ARM: "left_forearm",
  LEFT_SIDE: "left_side",
  RIGHT_UPPER_ARM: "right_upper_arm",
  RIGHT_LOWER_ARM: "right_forearm",
  RIGHT_SIDE: "right_side",
  HIPS_TILT: "hips_tilt",
  LEFT_THIGH: "left_thigh",
  LEFT_CALF: "left_calf",
  RIGHT_THIGH: "right_thigh",
  RIGHT_CALF: "right_calf",
});

export const RelativeAnglesNames = Object.freeze({
  LEFT_SHOULDER: "left_shoulder",
  RIGHT_SHOULDER: "right_shoulder",
  LEFT_ELBOW: "left_elbow",
  RIGHT_ELBOW: "right_elbow",
  LEFT_HIP: "left_hip",
  RIGHT_HIP: "right_hip",
  LEFT_KNEE: "left_knee",
  RIGHT_KNEE: "right_knee",
  TRUNK_LATERO_FLEXION: "trunk_latero_flexion",
  LEFT_ANKLE_DORSIFLEXION: "left_ankle_dorsiflexion",
  RIGHT_ANKLE_DORSIFLEXION: "right_ankle_dorsiflexion",
});
