import { initAndGetDetector } from "@/utils/detector";
import { isNullOrUndefined } from "@/utils/misc";
import * as poseDetection from "@tensorflow-models/pose-detection";

const detectorVuexModule = {
  namespaced: true,
  state: {
    isLoading: false,
    isDetecting: false,
    shouldInitModel: true,
    modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING,
    confidenceThreshold: 0.3,
    detector: null,
    keypoints: null,
    angleNames: [],
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isDetecting: (state) => state.isDetecting,
    modelType: (state) => state.modelType,
    confidenceThreshold: (state) => state.confidenceThreshold,
    shouldInitModel: (state) => state.shouldInitModel,
    getDetector: (state) => state.detector,
    getKeypoints: (state) => state.keypoints,
    getAngleNames: (state) => state.angleNames,
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsDetecting(state, isDetecting) {
      state.isDetecting = isDetecting;
    },
    setModelType(state, modelType) {
      state.modelType = modelType;
    },
    setConfidenceThreshold(state, confidenceThreshold) {
      state.confidenceThreshold = confidenceThreshold;
    },
    setShouldInitModel(state, shouldInitModel) {
      state.shouldInitModel = shouldInitModel;
    },
    setDetector(state, detector) {
      state.detector = detector;
    },
    setKeypoints(state, keypoints) {
      state.keypoints = keypoints;
    },
    resetAngles(state) {
      state.angleNames = [];
    },
    addAngleName(state, [angleName, angleState, onlySegments]) {
      state.angleNames.push([angleName, angleState, onlySegments]);
    },
    removeAngleName(state, angleName) {
      state.angleNames = state.angleNames.filter((a) => a[0] !== angleName);
    },
    setAngleState(state, [angleName, angleState]) {
      const index = state.angleNames.findIndex((a) => a[0] === angleName);
      if (index > -1) {
        const angleAtIndex = state.angleNames[index];
        state.angleNames[index] = [
          angleAtIndex[0],
          angleState,
          angleAtIndex[2],
          angleAtIndex[3],
          angleAtIndex[4],
        ];
      }
    },
    toggleShouldReverse(state, angleName) {
      const index = state.angleNames.findIndex((a) => a[0] === angleName);
      if (index > -1) {
        const angleAtIndex = state.angleNames[index];
        state.angleNames[index] = [
          angleAtIndex[0],
          angleAtIndex[1],
          angleAtIndex[2],
          !angleAtIndex[3],
          angleAtIndex[4],
        ];
        console.log(state.angleNames[index]);
      }
    },
    setAngleTarget(state, [angleName, target]) {
      const index = state.angleNames.findIndex((a) => a[0] === angleName);
      if (index > -1) {
        const angleAtIndex = state.angleNames[index];
        state.angleNames[index] = [
          angleAtIndex[0],
          angleAtIndex[1],
          angleAtIndex[2],
          angleAtIndex[3],
          target,
        ];
      }
    },
    removeSegmentsOnly(state) {
      state.angleNames = state.angleNames.filter((ta) => !ta[2]);
    },
    unsetSegmentsOnly(state, angleName) {
      const index = state.angleNames.findIndex((a) => a[0] === angleName);
      if (index > -1) {
        const angleAtIndex = state.angleNames[index];
        state.angleNames[index] = [
          angleAtIndex[0],
          angleAtIndex[1],
          false,
          angleAtIndex[3],
          angleAtIndex[4],
        ];
      }
    },
  },
  actions: {
    updateIsDetecting({ commit }, isDetecting) {
      commit("setIsDetecting", isDetecting);
    },
    async init({ commit, state }, { modelType = null, isDetecting = false }) {
      if (!isNullOrUndefined(modelType) && modelType !== state?.modelType) {
        commit("setModelType", modelType);
        commit("setShouldInitModel", true);
      }

      if (state.shouldInitModel) {
        commit("setIsLoading", true);
        const detector = await initAndGetDetector(state?.modelType);
        commit("setShouldInitModel", false);
        commit("setDetector", detector);
        commit("setIsDetecting", true);
        setTimeout(() => {
          if (!isDetecting) {
            commit("setIsDetecting", false);
          }
          commit("setIsLoading", false);
        }, 500);
      }
    },
    updateKeypoints({ commit }, keypoints) {
      commit("setKeypoints", keypoints);
    },
    addAngleName({ commit }, [angleName, angleState, onlySegments]) {
      commit("addAngleName", [angleName, angleState, onlySegments]);
    },
    updateAngleState({ commit }, [angleName, angleState]) {
      commit("setAngleState", [angleName, angleState]);
    },
    removeAngleName({ commit }, angleName) {
      commit("removeAngleName", angleName);
    },
    updateConfidenceThreshold({ commit }, confidenceThreshold) {
      commit("setConfidenceThreshold", confidenceThreshold);
    },
    updateShouldReverse({ commit }, angleName) {
      commit("toggleShouldReverse", angleName);
    },
    updateTarget({ commit }, [angleName, target]) {
      commit("setAngleTarget", [angleName, target]);
    },
    filterBySegmentsOnly({ commit }) {
      commit("removeSegmentsOnly");
    },
    updateSegmentsOnly({ commit }, angleName) {
      commit("unsetSegmentsOnly", angleName);
    },
    async setAssessmentAngles({ dispatch, commit }, angles) {
      await dispatch("init", {});
      commit("resetAngles");
      angles.forEach(([name, state, target, shouldReverse]) => {
        dispatch("addAngleName", [name, state, false]);
        dispatch("updateTarget", [name, target]);
        if (shouldReverse) {
          dispatch("updateShouldReverse", name);
        }
      });
      setTimeout(() => dispatch("updateIsDetecting", true), 600);
    },
    clearAngles({ commit }) {
      commit("resetAngles");
    },
  },
};

export default detectorVuexModule;
